.services-section {
    text-align: center;
    padding: 60px 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    margin: 20px;
}

.services-heading {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 20px;
    color: #000;
}

.services-subheading {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #333;
}

.bold-text {
    font-weight: bold;
}

.service-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.service-card {
    flex: 1 1 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border-radius: 15px;
    padding: 20px;
    color: rgb(0, 0, 0);
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.service-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
}

.purple-gradient {
    background: linear-gradient(135deg, #6a1b9a 0%, #b39ddb 100%);
}

.red-gradient {
    background: linear-gradient(135deg, #9a1b2a 0%, #db9da4 100%);
}

.green-gradient {
    background: linear-gradient(135deg, #1b9a21 0%, #b1db9d 100%);
}
.orange-gradient {
    background: linear-gradient(135deg, #9a541b 0%, #dbb99d 100%);
}
.blue-gradient {
    background: linear-gradient(135deg, #00b4d8 0%, #90e0ef 100%);
}

.service-button {
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    color: inherit;
    position: relative;
    z-index: 1;
}

.service-button:hover {
    background: rgba(255, 255, 255, 1);
}
