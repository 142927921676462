.footer {
    background-color: #03045e;
    color: #fff;
    padding: 40px 20px;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
}

.footer-column {
    flex: 1;
    min-width: 200px;
    margin: 10px;
}

.footer-logo {
    height: 50px;
    margin-bottom: 20px;
}

.certifications {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.certification-logo {
    height: 50px;
    margin-bottom: 10px;
}

.footer-column h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.footer-column ul {
    list-style-type: none;
    padding: 0;
}

.footer-column li {
    margin-bottom: 10px;
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.footer-bottom p {
    margin: 0;
    margin-bottom: 10px;
}

.social-icons {
    display: flex;
}

.social-icon {
    height: 30px;
    margin: 0 10px;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        text-align: center;
        margin: 20px 0;
    }
}
