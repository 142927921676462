.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #ffffff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px; /* Adjust as needed */
    width: auto;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 30px;
}

.nav-links ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-links li {
    cursor: pointer;
    color: #000;
    font-weight: bold;
    padding: 10px 15px;
}

.nav-links li:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
}

.login-btn {
    background: none;
    border: 2px solid #000;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
    font-weight: bold;
}

.cta-button {
    background-color: #000;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 4px 0;
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background-color: #ffffff;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 20px;
    }

    .nav-links.open {
        display: flex;
    }

    .menu-icon {
        display: flex;
    }
}
