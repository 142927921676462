.price-simulator {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    border-radius: 15px;
    background-color: #f0f4f8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.price-simulator h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #003566;
}

.category-selector,
.package-selector {
    margin-bottom: 20px;
}

.category-selector label,
.package-selector label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    color: #003566;
}

.category-selector select,
.package-selector select {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.package-details {
    margin-top: 20px;
    text-align: left;
}

.package-details h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #0077b6;
}

.package-details ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
    color: #000;
}

.package-details p {
    font-size: 1.2em;
    margin: 10px 0;
    color: #000;
}
