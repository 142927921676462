body {
    margin: 0;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background: white;
    color: #000;
}

.home {
    text-align: center;
}

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 40px;
    background-color: rgba(3, 4, 94, 0.85);
    border-radius: 15px;
    margin: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.hero-content {
    max-width: 600px;
    text-align: left;
    color: #ffffff;
}

.hero-content h1 {
    font-size: 4em;
    margin-bottom: 20px;
    font-weight: bolder;
}

.hero-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.cta-button {
    background-color: #000;
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.cta-button:hover {
    background-color: #ffffff;
    color: #000;
    border: 2px solid #000;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

@media (min-width: 768px) {
    .hero-section {
        flex-direction: row;
    }

    .hero-image {
        margin-left: 20px;
    }
}

.service-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    background-color: #ffffff;
    border-radius: 15px;
    margin: 20px;
}

.service-section-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    padding: 30px;
}

.service-image-container {
    flex: 1;
}

.service-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.service-section-text {
    flex: 2;
    text-align: left;
    padding-left: 20px;
}

.service-heading {
    color: #00b4d8;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.service-subheading {
    color: #000;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
}

.service-description {
    font-size: 1.1em;
    color: #333;
    margin-bottom: 20px;
}

.service-stat {
    font-size: 1.1em;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
}

.read-more-button {
    background-color: #000;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.read-more-button:hover {
    background-color: #ffffff;
    color: #000;
    border: 2px solid #000;
}

@media (max-width: 768px) {
    .service-section-content {
        flex-direction: column;
        align-items: center;
    }

    .service-image-container {
        width: 100%;
        margin-bottom: 20px;
    }

    .service-section-text {
        padding-left: 0;
        text-align: center;
    }
}
